
/**
 * @name: 分成管理-分成记录
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 分成管理-分成记录
 * @update: 2024-07-18 14:45
 */
import { Vue, Component } from "vue-property-decorator"
import {
  splitRecordPageApi,
  splitRecordExportApi,
  splitRecordOtherApi
} from '@/apis/split/record'
import {
  ISplitRecord,
  ISplitRecordParams
} from '@/apis/split/record/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from '@/utils/common'
import Big from 'big.js'

@Component({})
export default class splitRecord extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ISplitRecord[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ISplitRecordParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: '关联订单编号',
        prop: 'orderId',
        width: 180,
        align: 'left'
      },
      {
        label: '关联订单编号',
        prop: 'orderSn',
        hide: true,
        search: true
      },
      {
        label: '设备编号',
        prop: 'deviceSn',
        align: 'center',
        search: true
      },
      {
        label: '消费单价(元)',
        prop: 'orderPrice',
        align: 'center',
      },
      {
        label: '消费时长(分钟)',
        prop: 'useDuration',
        align: 'center',
        slot: true
      },
      {
        label: '分账总额(元)',
        prop: 'payPrice',
        align: 'center',
      },
      {
        label: '平台分成(元)',
        prop: 'platformPrice',
        align: 'center',
      },
      {
        label: '代理商分成(元)',
        prop: 'agentPrice',
        align: 'center',
        slot: true
      },
      {
        label: '业务合作方分成(元)',
        prop: 'partnerPrice',
        align: 'center',
        slot: true
      },
      {
        label: '介绍人分成(元)',
        prop: 'introducePrice',
        align: 'center',
        slot: true
      },
      {
        label: '分成时间',
        prop: 'commissionTime',
        align: 'center',
        type: 'daterange',
        search: true
      }
    ]
  }

  payPriceTotal = 0

  platformPriceTotal = 0

  agentPriceTotal = 0

  partnerPriceTotal = 0

  introducePriceTotal = 0

  cooperatingDialog = false

  cooperatingList: any = []

  referringDialog = false

  referringList: any = []

  getList () {
    this.tableLoading = true

    const query: ISplitRecordParams = deepCopy(this.queryParam)
    if (query.commissionTime && query.commissionTime.length === 2) {
      query.commissionTimeStart = query.commissionTime[0]
      query.commissionTimeEnd = query.commissionTime[1]
    } else {
      query.commissionTimeStart = ''
      query.commissionTimeEnd = ''
    }
    delete query.commissionTime

    splitRecordPageApi(query).then(e => {
      if (e && e.data) {
        this.tableLoading = false
        this.payPriceTotal = e.data.payPriceTotal
        this.platformPriceTotal = e.data.platformPriceTotal
        this.agentPriceTotal = e.data.agentPriceTotal
        this.partnerPriceTotal = e.data.partnerPriceTotal
        this.introducePriceTotal = e.data.introducePriceTotal
        this.tableData = e.data.commissionRecordVoCommonPage.list
        this.tableTotal = e.data.commissionRecordVoCommonPage.total
      }
    })
  }

  exportExcel () {

    const query: ISplitRecordParams = deepCopy(this.queryParam)
    if (query.commissionTime && query.commissionTime.length === 2) {
      query.commissionTimeStart = query.commissionTime[0]
      query.commissionTimeEnd = query.commissionTime[1]
    } else {
      query.commissionTimeStart = ''
      query.commissionTimeEnd = ''
    }
    delete query.commissionTime

    splitRecordExportApi(query).then(e => {
      if (e && e.data) {
        exportFile(e.data, '分成记录.xlsx')
        this.$message.success('操作成功!')
      }
    })
  }

  getUseDuration (seconds: number) {
    if (!seconds) {
      return 0
    }
    if (seconds >= (5 * 60)) {
      // 向上
      return Math.ceil(new Big(seconds).div(60).toNumber())
    } else {
      // 向下
      return Math.floor(new Big(seconds).div(60).toNumber())
    }
  }

  openDialog (row: ISplitRecord, type: number) {
    splitRecordOtherApi(row.orderId, type).then(e => {
      if (e && e.data) {
        if (type == 1) {
          // 合作方
          this.cooperatingList = e.data
          this.cooperatingDialog = true
        } else {
          this.referringList = e.data
          this.referringDialog = true
        }
      }
    })
  }

  created () {
    this.getList()
  }
}
