/**
 * @name: 分成管理-分成记录接口文件
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 分成管理-分成记录接口文件
 * @update: 2024-07-18 14:45
 */
import api from '@/request/api'
import {IPageRes} from "@/apis/page";
import { ISplitRecordParams, ISplitRecordRes } from './types';

export const splitRecordPageApi = (params: ISplitRecordParams) => api.get<ISplitRecordRes>('/admin/commission/record/queryByPage', params)

export const splitRecordExportApi = (params: ISplitRecordParams) => api.get('/admin/commission/record/commissionRecordExport', params, 'blob')

export const splitRecordOtherApi = (orderId: string, type: number) => api.get('/admin/commission/record/queryUserCommissionDetail', {orderId, type})
